<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="mb-2">
       <b-col
          cols="12"
          md="12"
          class="mb-2"
        >
          <label>Pokok Laporan</label>
           <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            v-model="localData.pokok_laporan_id"
            :options="pokokLaporanOptions"
          >
            <template slot="selected-option" slot-scope="option">
              <div class="flex">
                <div class="col">
                  <span class="fa" :class="option.icon"></span>
                  <span v-html="option.label"></span>
                </div>
              </div>
            </template>
            <template slot="option" slot-scope="option">
                <span class="fa" :class="option.icon" v-html="option.label"></span>
            </template>
          </v-select>
            <small class="mt-1" style="color: red;" v-if="error.pokok_laporan_id">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="mb-md-0 mb-2"
        >
           <div class="mr-1">
              <label for="">Saran</label>
               <quill-editor
                  id="saran"
                  :options="snowOption"
                  style="height: 400px; overflow-y: auto;"
                  v-model="localData.saran"
                />
            </div>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
            <div class="mt-2">
              <b-button class="btn btn-primary" variant="primary" @click="updateData">Simpan</b-button>
            </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput, BFormFile, BMedia, BMediaBody, BMediaAside, BLink, BCardText, BImg, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  uploadFileLogo,
} from '@/connection-api/instansi'
import {
  getSaranAnalisisById,
  updateSaranAnalisis,
} from '@/connection-api/master'
import { quillEditor } from 'vue-quill-editor'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormInput,
    BFormFile,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BCardText,
    BImg,
    BFormTextarea,
    quillEditor,
  },
  data() {
    return {
        snowOption: {
        theme: 'snow',
      },
      error: {},
      localData: {
        logo: require('@/assets/images/logo/logo-dev.png'),
        file: null,
        negara_id: null,
        provinsi_id: null,
        kota_id: null,
      },
    }
  },
  directives: {
    Ripple,
  },
  props: {
    instansiId: {},
    pokokLaporanOptions: {},
  },
  async mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const response = await getSaranAnalisisById(this.instansiId)
      if(response.data.success === true)
      {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Saran',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Data berhasil didapatkan!.',
          },
        })
        this.localData = response.data.data
      }
    },
    close() {
      this.$emit('emitCloseEditData')
    },
    async uploadLogo(id)
    {
      if(this.localData.file !== null)
      {
        const responseUpload = await uploadFileLogo(id, this.localData.file, event => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
      }
    },
    async updateData()
    {
      const response = await updateSaranAnalisis(this.localData)
      if(response.data.success === true)
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Saran',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.$emit('refetchData')
          this.$root.$emit('bv::hide::modal', 'modal_edit_saran_analisis_' + this.instansiId);

      }
      else
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Saran',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
          this.error = response.data.error
      }
    },
     onFileChangeLogo(val) {
      if(val !== null)
      {
        this.localData.logo = URL.createObjectURL(val)
      }
    },
    changeUPT(value) {
      this.$emit('emitReceiverUPT', value)
      this.$emit('update:kanwilFilter', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
