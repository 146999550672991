<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Email</label>
           <b-form-input
              id="email"
              autofocus
              trim
              placeholder="Email"
              v-model="localData.email"
              :type="'email'"
            />
            <small class="mt-1" style="color: red;" v-if="error.email">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Nama</label>
           <b-form-input
              id="nama"
              autofocus
              trim
              placeholder="Nama"
              v-model="localData.nama"
            />
            <small class="mt-1" style="color: red;" v-if="error.nama">{{error.message}}</small>
        </b-col>
         <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Jenis Kelamin</label>
           <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            v-model="localData.jenis_kelamin"
            :options="jenisKelaminOptions"
          />
            <small class="mt-1" style="color: red;" v-if="error.jenis_kelamin">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>No KTP</label>
           <b-form-input
              id="no_ktp"
              autofocus
              trim
              placeholder="No KTP"
              v-model="localData.no_ktp"
              :type="'number'"
            />
            <small class="mt-1" style="color: red;" v-if="error.no_ktp">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Pekerjaan</label>
           <b-form-input
              id="pekerjaan"
              autofocus
              trim
              placeholder="Pekerjaan"
              v-model="localData.pekerjaan"
            />
            <small class="mt-1" style="color: red;" v-if="error.pekerjaan">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Alamat KTP</label>
           <b-form-input
              id="alamat_ktp"
              autofocus
              trim
              placeholder="Alamat KTP"
              v-model="localData.alamat_ktp"
            />
            <small class="mt-1" style="color: red;" v-if="error.alamat_ktp">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Alamat Surat</label>
           <b-form-input
              id="alamat_surat"
              autofocus
              trim
              placeholder="Alamat Surat"
              v-model="localData.alamat_surat"
            />
            <small class="mt-1" style="color: red;" v-if="error.alamat_surat">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Telp</label>
           <b-form-input
              id="telp"
              autofocus
              trim
              placeholder="Telp"
              v-model="localData.telp"
              :type="'number'"
            />
            <small class="mt-1" style="color: red;" v-if="error.telp">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Provinsi</label>
           <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            v-model="localData.provinsi_id"
            :options="provinsiOptions"
            @input="(val) => $emit('emitLoadKota', val)"
          />
            <small class="mt-1" style="color: red;" v-if="error.provinsi_id">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Kota</label>
           <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            v-model="localData.kota_id"
            :options="kotaOptions"
          />
            <small class="mt-1" style="color: red;" v-if="error.kota_id">{{error.message}}</small>
        </b-col>
         <b-col
          cols="12"
          md="6"
          class="mb-2 mt-2"
        >
            <b-button class="btn" variant="primary" @click="tambahData">Tambah</b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput, BFormFile, BMedia, BMediaBody, BMediaAside, BLink, BCardText, BImg, BFormTextarea, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    tambahKuasaPelapor,
    uploadFileKuasaPelapor,
} from '@/connection-api/master'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormInput,
    BFormFile,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BCardText,
    BImg,
    BFormTextarea,
    BFormDatepicker,
  },
  data() {
    return {
      error: {},
      localData: {
        logo: require('@/assets/images/logo/logo-dev.png'),
        email: null,
        nama: null,
        jenis_kelamin: null,
        no_ktp: null,
        pekerjaan: null,
        alamat_ktp: null,
        alamat_surat: null,
        telp: null,
        provinsi_id: null,
        kota_id: null,
        jenis_kelamin: null,
      },
    }
  },
  directives: {
    Ripple,
  },
  props: {
    pihak: {},
    negaraOptions: {},
    provinsiOptions: {},
    kotaOptions: {},
    jenisKelengkapanOptions: {},
    jenisKelaminOptions: {},
  },
  methods: {
    async tambahData()
    {
      if(this.localData.file !== undefined)
      {
        const responseUpload = await uploadFileKuasaPelapor(this.localData.file, event => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
        this.localData.nama_file = responseUpload.data.file
      }

      const response = await tambahKuasaPelapor(this.localData)
      if(response.data.success === true)
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kuasa Pelapor',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.$emit('refetchData')
          this.$emit('closeTambahData')
      }
      else
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kuasa Pelapor',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
          this.error = response.data.error
      }
    },
     onFileChangeLogo(val) {
      if(val !== null)
      {
        this.localData.logo = URL.createObjectURL(val)
      }
    },
    changeUPT(value) {
      this.$emit('emitReceiverUPT', value)
      this.$emit('update:kanwilFilter', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
