<template>
    <b-row>
      <b-col
        xl="6"
      >
        <b-form-group
          label="No Register"
          label-for="No Register"
        >
          <b-form-input
            id="no_register"
            autofocus
            trim
            placeholder=""
            :disabled="true"
          />
        </b-form-group>

        <b-form-group
          label="Tgl Register"
          label-for="tgl_register"
        >
          <b-form-input
            id="tgl_register"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            
          />
        </b-form-group>

        <b-form-group
          label="Nama Tim"
          label-for="nama_tim"
        >
          <b-form-input
            id="nama_tim"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            
          />
        </b-form-group>
        <b-form-group
          label="Penanggung Jawab"
          label-for="penanggung_jawab"
        >
          <b-form-input
            id="penanggung_jawab"
            autofocus
            trim
            placeholder=""
            :disabled="true"
        
          />
        </b-form-group>
        <b-form-group
          label="Status"
          label-for="status"
        >
          <b-form-input
            id="status"
            autofocus
            trim
            placeholder=""
            :disabled="true"
          
          />
        </b-form-group>
        <b-form-group
          label="Durasi Pengerjaan"
          label-for="Durasi Pengerjaan"
        >
          <b-form-input
            id="Durasi Pengerjaan"
            autofocus
            trim
            placeholder=""
            :disabled="true"
          
          />
        </b-form-group>
        <b-form-group
          label="Waktu Proses Terakhir"
          label-for="Waktu Proses Terakhir"
        >
          <b-form-input
            id="Waktu Proses Terakhir"
            autofocus
            trim
            placeholder=""
            :disabled="true"
          
          />
        </b-form-group>
        <b-form-group
          label="Created Date"
          label-for="Created Date"
        >
          <b-form-input
            id="Created Date"
            autofocus
            trim
            placeholder=""
            :disabled="true"
          
          />
        </b-form-group>
        <b-form-group
          label="Created By"
          label-for="Created By"
        >
          <b-form-input
            id="Created By"
            autofocus
            trim
            placeholder=""
            :disabled="true"
          
          />
        </b-form-group>
        <b-form-group
          label="Last Update Date"
          label-for="Last Update Date"
        >
          <b-form-input
            id="Last Update Date"
            autofocus
            trim
            placeholder=""
            :disabled="true"
          
          />
        </b-form-group>
        <b-form-group
          label="Last Update By"
          label-for="Last Update By"
        >
          <b-form-input
            id="Last Update By"
            autofocus
            trim
            placeholder=""
            :disabled="true"
          
          />
        </b-form-group>
      </b-col>
    </b-row>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Kerjasama from '@/views/laporan/list/List.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BModal,
  BFormFile,
  BMedia,
  BMediaBody,
  BFormCheckbox,
} from 'bootstrap-vue'
import {
  hapusPihak,
} from '@/connection-api/laporan'
import { required, email } from '@validations'
import flatPickr from "vue-flatpickr-component";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import 'flatpickr/dist/flatpickr.css';
import ModalInstansi from "./ModalInstansi.vue"
import ModalNarahubung from "./ModalNarahubung.vue"
// import ModalUnitUtama from "./ModalUnitUtama.vue"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    flatPickr,
    BModal,
    BFormFile,
    BMedia,
    BMediaBody,
    Kerjasama,
    ModalInstansi,
    ModalNarahubung,
    // ModalUnitUtama,
     BFormCheckbox,
  },
  data() {
    return {
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          dateFormat: "d-m-Y",
          locale: Indonesian,
        },
      },
      required,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  props: {
    localData: {},
  },
  methods: {
    updateParaPihak() {
      this.$emit('emitUpdateParaPihak')
    },
    async hapusData(id){
      if(window.confirm('Apakah anda yakin?'))
      {
        const resp = await hapusPihak(id)
        if(resp.data.success === true)
        {
          this.makeToast('success', resp.data.message)
          this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
        }
      }
    },
    reloadDataKerjasama()
    {
      this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Kerjasama',
        variant,
        solid: true,
        autoHideDelay: 5000,
      })
    },
  }
}
</script>
