<template>
          <b-row>
            
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mt-2" style="margin-bottom: 0px;">
                Pihak {{localData.pihak_ke}} {{localData.btn}}
              </h5>
            </b-col>
             <b-col
              cols="6"
              class="mb-2"
            >
              <div class="d-flex justify-content-end">
                <modal-instansi class="mr-1" :localData="localData" @emitGetDetailDataKerjasama="reloadDataKerjasama" @emitUpdateParaPihak="updateParaPihak" />
                <!-- <modal-unit-utama :localData="localData" @emitGetDetailDataKerjasama="reloadDataKerjasama" @emitUpdateParaPihak="updateParaPihak" /> -->
              </div>
            </b-col>
             <b-col
              cols="6"
              class="mb-2"
            >
              <div class="d-flex justify-content-end">
                 <modal-narahubung :localData="localData" @emitGetDetailDataKerjasama="reloadDataKerjasama" @emitUpdateParaPihak="updateParaPihak"/>
              </div>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nama Instansi"
                label-for="Nama Instansi"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nama Instansi"
                  rules="required"
                >
                  <b-form-input
                    id="Nama Instansi"
                    v-model="localData.nama_instansi"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Masukkan nama instansi"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
               <b-form-group
                label="Nama Nara Hubung/Kontak Pengusul"
                label-for="Nama Nara Hubung/Kontak Pengusul"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nama Nara Hubung/Kontak Pengusul"
                  rules="required"
                >
                  <b-form-input
                    id="Nama Nara Hubung/Kontak Pengusul"
                    v-model="localData.nara_hubung"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Nara Hubung"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
             <b-form-group
                label="Jabatan Penandatangan"
                label-for="Jabatan Penandatangan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Jabatan Penandatangan"
                  rules="required"
                >
                  <b-form-input
                    :disabled="$route.params.readonly"
                    id="Jabatan Penandatangan"
                    v-model="localData.jabatan_ttd"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Jabatan Penandatangan"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
             <b-form-group
                label="Alamat Pos Eletronik"
                label-for="Alamat Pos Eletronik"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Alamat Pos Eletronik"
                  rules="required|email"
                >
                  <b-form-input
                    id="Alamat Pos Eletronik"
                    v-model="localData.email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Email"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Alamat"
              >
                <b-form-group
                  label="Alamat"
                  label-for="Alamat"
                  :state="errors.length > 0 ? false:null"
                >
                   <b-form-textarea
                      id="Alamat"
                      v-model="localData.alamat"
                      placeholder="Alamat..."
                      rows="3"
                      :disabled="true"
                    >
                    </b-form-textarea>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      Alamat wajib diisi!
                    </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
               <b-form-group
                label="Telepon"
                label-for="telepon"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Telepon"
                  rules="required"
                >
                  <b-form-input
                    id="Telepon"
                    v-model="localData.telepon"
                    :state="errors.length > 0 ? false:null"
                    placeholder="(021) 8225456"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12" class="mb-3">
              <div class="d-flex justify-content-end">
                <b-button class="btn" variant="outline-danger" @click="hapusData(localData.id)" v-if="!$route.params.readonly">Hapus</b-button>
              </div>
            </b-col>
          </b-row>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Kerjasama from '@/views/laporan/list/List.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BModal,
  BFormFile,
  BMedia,
  BMediaBody,
} from 'bootstrap-vue'
import {
  hapusPihak,
} from '@/connection-api/laporan'
import { required, email } from '@validations'
import flatPickr from "vue-flatpickr-component";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import 'flatpickr/dist/flatpickr.css';
import ModalInstansi from "./ModalInstansi.vue"
import ModalNarahubung from "./ModalNarahubung.vue"
// import ModalUnitUtama from "./ModalUnitUtama.vue"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    flatPickr,
    BModal,
    BFormFile,
    BMedia,
    BMediaBody,
    Kerjasama,
    ModalInstansi,
    ModalNarahubung,
    // ModalUnitUtama,
  },
  data() {
    return {
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          dateFormat: "d-m-Y",
          locale: Indonesian,
        },
      },
      required,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  props: {
    localData: {},
  },
  methods: {
    updateParaPihak() {
      this.$emit('emitUpdateParaPihak')
    },
    async hapusData(id){
      if(window.confirm('Apakah anda yakin?'))
      {
        const resp = await hapusPihak(id)
        if(resp.data.success === true)
        {
          this.makeToast('success', resp.data.message)
          this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
        }
      }
    },
    reloadDataKerjasama()
    {
      this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Kerjasama',
        variant,
        solid: true,
        autoHideDelay: 5000,
      })
    },
  }
}
</script>
