<template>

  <div>

     <!-- Tambah Data -->
    <div>
       <b-modal id="modal_tambah_kuasa_pelapor" size="lg" title="Kuasa Pelapor" ref="btnClose" hide-footer v-if="$route.params.laporan_id">
        <tambah-data
        :negaraOptions="negaraOptions.options"
        :provinsiOptions="provinsiOptions.options"
        :kotaOptions="kotaOptions.options"
        :jenisKelengkapanOptions="jenisKelengkapanOptions.options"
        :jenisKelaminOptions="jenisKelaminOptions.options"
        @emitLoadKota="onchangeProvinsi"
        @refetchData="refetchData"
        @emitPilihInstansi="pilihInstansi"
        @emitCloseTambahData="closeTambahData"
        @closeTambahData="closeTambahData"
       />
      </b-modal>
    </div>

    <!-- Filters -->
    <!-- <list-filters
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button class="btn" @click="TambahData()" v-b-modal.modal_tambah_kuasa_pelapor variant="outline-primary" v-if="$can('create','kuasa-pelapor') && !$route.params.mode">Tambah Kuasa Pelapor</b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchDatas"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(nama)="data">
          <b-media vertical-align="center">
              {{ data.item.nama }}
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
            <div v-if="$route.params.laporan_id" class="d-flex">
              <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="outline-success"  v-if="data.item.terpilih === true"
              >
                Terpilih
              </b-button>
              <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="primary" v-b-modal="`modal-select-kuasa-pelapor-${data.item.id}`"  v-else
              >
                Pilih
              </b-button>
               <b-button class="mr-1 btn-icon btn btn-relief-primary" v-b-modal="'modal_edit_kuasa_pelapor_'+data.item.id" variant="outline-primary"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-modal :id="'modal_edit_kuasa_pelapor_'+data.item.id" size="lg" title="Kuasa Pelapor" ref="btnClose" hide-footer v-if="$route.params.laporan_id">
             <edit-data
                :instansiId="data.item.id"
                @emitCloseEditData="closeEditData"
                :negaraOptions="negaraOptions.options"
                :provinsiOptions="provinsiOptions.options"
                :kotaOptions="kotaOptions.options"
                :jenisKelengkapanOptions="jenisKelengkapanOptions.options"
                @emitLoadKota="onchangeProvinsi"
                @refetchData="refetchData"
                @emitReloadDataKerjasama="reloadDataKerjasama"
              />
            </b-modal>
             <b-button class="mr-1 btn-icon btn btn-relief-danger" v-b-modal="`modal-hapus-kuasa-pelapor-${data.item.id}`" variant="outline-danger"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
            
            <div v-else>
              <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="info" @click="editData(data.item.id)" title="Ubah Data">
                <feather-icon icon="EditIcon" />
              </b-button>

              <b-button
                class="btn-icon btn btn-relief-primary"
                variant="outline-danger"
               title="Hapus Data" alt="Hapus Data"
               v-b-modal="`modal-hapus-kuasa-pelapor-${data.item.id}`"
              >
                <feather-icon icon="TrashIcon" />
                <!-- <span class="align-middle ml-50">Delete</span> //-->
              </b-button>
            </div>


            <div>
              <b-modal :id="`modal-hapus-kuasa-pelapor-${data.item.id}`" centered title="Konfirmasi">
                <div class="d-flex justify-content-center">
                  <h4 class="my-4">Apakah anda yakin ingin menghapus pelapor {{data.item.nama}}?</h4>
                </div>
                <template #modal-footer>
                  <b-button variant="danger" @click="deleteData(data.item.id, data.item.nama)">Hapus</b-button>
                  <b-button variant="secondary" @click="$bvModal.hide(`modal-hapus-kuasa-pelapor-${data.item.id}`)">Batal</b-button>
                </template>
              </b-modal>
            </div>


            <div>
              <b-modal :id="`modal-select-kuasa-pelapor-${data.item.id}`" centered title="Konfirmasi">
                <div class="d-flex justify-content-center">
                  <h4 class="my-4">Apakah anda yakin ingin memilih kuasa pelapor atas nama {{data.item.nama}} untuk laporan Anda?</h4>
                </div>
                <template #modal-footer>
                  <b-button variant="primary" @click="pilihInstansi(data.item.id)">Pilih</b-button>
                  <b-button variant="secondary" @click="$bvModal.hide(`modal-select-kuasa-pelapor-${data.item.id}`)">Batal</b-button>
                </template>
              </b-modal>
            </div>
          <!-- </b-dropdown> -->
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Menampilkan {{ dataMeta.from }} per {{ dataMeta.to }} dari {{ dataMeta.of }} data </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/connection-api/tambah-kuasa-pelapor/storeModule'
import useList from '@/connection-api/tambah-kuasa-pelapor/list'
import TambahData from './TambahData.vue'
import EditData from './EditData.vue'
import listFilters from './ListFilters.vue'
import {
  getNegara,
  getProvinsi,
  getKota,
  deleteKuasaPelapor,
  getJenisKelengkapan,
  selectKuasaPelapor,
  unselectKuasaPelapor,
} from '@/connection-api/master'
// import {
//   pilihUnitUtama,
// } from '@/connection-api/kerjasama'
// import {
//   undangUnitUtama,
// } from '@/connection-api/jadwal-pembahasan'

export default {
  components: {
    listFilters,
    TambahData,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    // BLink,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    EditData,
    vSelect,
  },
  data() {
    return {
      instansiId: null,
      showTambahData: false,
      showEditData: false,
      negaraOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Negara --' },
        ],
      },
      provinsiOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Provinsi --' },
        ],
      },
      kotaOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kota --' },
        ],
      },
      jenisKelengkapanOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Dokumen --' },
        ],
      },
      jenisKelaminOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Kelamin --' },
          { value: 'Laki-Laki', label: 'Laki-Laki' },
          { value: 'Perempuan', label: 'Perempuan' },
        ],
      },
    }
  },
  props: {
    pihak: {},
    jadwal_pembahasan_id: {},
  },
  async mounted() {
      const respNegara = await getNegara()
      this.mappingSelectOption(respNegara.data, this.negaraOptions.options)

      const respProvinsi = await getProvinsi()
      this.mappingSelectOption(respProvinsi.data, this.provinsiOptions.options)

      const jenisKelengkapan = await getJenisKelengkapan()
      this.mappingSelectOption(jenisKelengkapan.data, this.jenisKelengkapanOptions.options)
  },
  methods: {
    closeEditData(){
      this.showEditData = false
    },
    closeTambahData(){
      this.showTambahData = false
    },
    editData(id) {
      this.showTambahData = false
      this.showEditData = false
      this.instansiId = id
      this.showEditData = true
    },
    async pilihInstansi(id)
    {
      const data = {
        kuasa_pelapor_id: id,
        laporan_id: this.$route.params.laporan_id,
      }
      const response = await selectKuasaPelapor(data)
      if(response.data.success === true)
      {
         this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Kuasa Pelapor',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
            this.refetchData()
            this.$emit('reloadData')
            this.$root.$emit('bv::hide::modal', 'modal-select-kuasa-pelapor-' + id);
      }
    },
    async undang(id, jadwalPembahasanId)
    {
      const data = {
        jadwal_pembahasan_id: jadwalPembahasanId,
        unit_utama_id: id,
      }

      const response = await undangUnitUtama(data)
      if(response.data.success === true)
      {
         this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Kuasa Pelapor',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
            this.refetchData()
      }
    },
    reloadDataKerjasama() {
      this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
    },
    async deleteData(id, nama) 
    {
      
        const data = {
          id: id
        }
        const response = await deleteKuasaPelapor(data)
        if(response.data.success === true)
        {
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Kuasa Pelapor',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Data berhasil dihapus',
              },
            })
            this.refetchData()
            this.$emit('reloadData')
            this.$root.$emit('bv::hide::modal', 'modal-hapus-kuasa-pelapor-' + id);
        }
        else
        {
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Kuasa Pelapor',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.message,
              },
            })
        }
      
    },
    async onchangeProvinsi(provinsiId)
    {
      const data = {
        provinsi_id: provinsiId
      }
      this.kotaOptions = {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kota --' },
        ],
      }
      const respKota = await getKota(data)
      this.mappingSelectOption(respKota.data, this.kotaOptions.options)
    },
    TambahData()
    {
      if(this.showTambahData === false)
      {
        this.showTambahData = true;
        this.showEditData = false
      }
      else
      {
        this.showTambahData = false;
        this.showEditData = false
      }
    },
    emitAddSuccess(assetId) {
      this.$router.replace(`/tambah-kuasa-pelapor/edit/${assetId}`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kuasa Pelapor',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Data berhasil di tambahkan',
            },
          })
        })
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'tambah-kuasa-pelapor'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      laporanId,
 
    } = useList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      laporanId,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
