<template>
    <b-row>
      <b-col
        xl="6"
      >
        <b-form-group
          label="No Penerimaan"
          label-for="No Penerimaan"
        >
          <b-form-input
            id="no_penerimaan"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.no_penerimaan"
          />
        </b-form-group>

        <b-form-group
          label="Tgl Penerimaan"
          label-for="tgl_penerimaan"
        >
          <b-form-input
            id="tgl_penerimaan"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.tgl_penerimaan"
          />
        </b-form-group>

        <b-form-group
          label="Nomor Register"
          label-for="no_register"
        >
          <b-form-input
            id="no_register"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.no_register"
          />
        </b-form-group>
        <b-form-group
          label="Tanggal Register"
          label-for="tgl_register"
        >
          <b-form-input
            id="tgl_register"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.tgl_register"
          />
        </b-form-group>
        <b-form-group
          label="No Surat"
          label-for="no_surat"
        >
          <b-form-textarea
            id="no_surat"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.no_surat"
          />
        </b-form-group>
        <b-form-group
          label="Tanggal Surat"
          label-for="Tanggal Surat"
        >
          <b-form-input
            id="Tanggal Surat"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.tgl_surat"
          />
        </b-form-group>
        <b-form-group
          label="Jenis Penyampaian"
          label-for="Jenis Penyampaian"
        >
          <b-form-input
            id="Jenis Penyampaian"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.jenis_penyampaian"
          />
        </b-form-group>
        <b-form-group
          label="Diterima Melalui"
          label-for="Diterima Melalui"
        >
          <b-form-input
            id="Diterima Melalui"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.diterima_melalui"
          />
        </b-form-group>
        <b-form-group
          label="Perihal"
          label-for="Perihal"
        >
          <b-form-textarea
            id="Perihal"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.perihal"
          />
        </b-form-group>
        <b-form-group
          label="Jenis Perkara"
          label-for="Jenis Perkara"
        >
          <b-form-input
            id="Jenis Perkara"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.jenis_perkara"
          />
        </b-form-group>
        <b-form-group
          label="Lingkungan Peradilan"
          label-for="Lingkungan Peradilan"
        >
          <b-form-input
            id="Lingkungan Peradilan"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.lingkungan_peradilan"
          />
        </b-form-group>
        <b-form-group
          label="Lokasi Laporan"
          label-for="Lokasi Laporan"
        >
          <b-form-input
            id="Lokasi Laporan"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.kota"
          />
        </b-form-group>
        <b-form-group
          label="No Putusan"
          label-for="No Putusan"
        >
          <b-form-input
            id="No Putusan"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.no_putusan"
          />
        </b-form-group>
        <b-form-group
          label="Keterangan"
          label-for="Keterangan"
        >
          <b-form-textarea
            id="Keterangan"
            autofocus
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.keterangan"
          />
        </b-form-group>
      </b-col>
    </b-row>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Kerjasama from '@/views/laporan/list/List.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BModal,
  BFormFile,
  BMedia,
  BMediaBody,
  BFormCheckbox,
} from 'bootstrap-vue'
import {
  hapusPihak,
} from '@/connection-api/laporan'
import { required, email } from '@validations'
import flatPickr from "vue-flatpickr-component";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import 'flatpickr/dist/flatpickr.css';
import ModalInstansi from "./ModalInstansi.vue"
import ModalNarahubung from "./ModalNarahubung.vue"
// import ModalUnitUtama from "./ModalUnitUtama.vue"

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    flatPickr,
    BModal,
    BFormFile,
    BMedia,
    BMediaBody,
    Kerjasama,
    ModalInstansi,
    ModalNarahubung,
    // ModalUnitUtama,
     BFormCheckbox,
  },
  data() {
    return {
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          dateFormat: "d-m-Y",
          locale: Indonesian,
        },
      },
      required,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  props: {
    localData: {},
  },
  methods: {
    updateParaPihak() {
      this.$emit('emitUpdateParaPihak')
    },
    async hapusData(id){
      if(window.confirm('Apakah anda yakin?'))
      {
        const resp = await hapusPihak(id)
        if(resp.data.success === true)
        {
          this.makeToast('success', resp.data.message)
          this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
        }
      }
    },
    reloadDataKerjasama()
    {
      this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Kerjasama',
        variant,
        solid: true,
        autoHideDelay: 5000,
      })
    },
  }
}
</script>
