var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mt-2",staticStyle:{"margin-bottom":"0px"}},[_vm._v(" Pihak "+_vm._s(_vm.localData.pihak_ke)+" "+_vm._s(_vm.localData.btn)+" ")])]),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('modal-instansi',{staticClass:"mr-1",attrs:{"localData":_vm.localData},on:{"emitGetDetailDataKerjasama":_vm.reloadDataKerjasama,"emitUpdateParaPihak":_vm.updateParaPihak}})],1)]),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('modal-narahubung',{attrs:{"localData":_vm.localData},on:{"emitGetDetailDataKerjasama":_vm.reloadDataKerjasama,"emitUpdateParaPihak":_vm.updateParaPihak}})],1)]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nama Instansi","label-for":"Nama Instansi"}},[_c('validation-provider',{attrs:{"name":"Nama Instansi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Nama Instansi","state":errors.length > 0 ? false:null,"placeholder":"Masukkan nama instansi","disabled":true},model:{value:(_vm.localData.nama_instansi),callback:function ($$v) {_vm.$set(_vm.localData, "nama_instansi", $$v)},expression:"localData.nama_instansi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nama Nara Hubung/Kontak Pengusul","label-for":"Nama Nara Hubung/Kontak Pengusul"}},[_c('validation-provider',{attrs:{"name":"Nama Nara Hubung/Kontak Pengusul","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Nama Nara Hubung/Kontak Pengusul","state":errors.length > 0 ? false:null,"placeholder":"Nara Hubung","disabled":true},model:{value:(_vm.localData.nara_hubung),callback:function ($$v) {_vm.$set(_vm.localData, "nara_hubung", $$v)},expression:"localData.nara_hubung"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Jabatan Penandatangan","label-for":"Jabatan Penandatangan"}},[_c('validation-provider',{attrs:{"name":"Jabatan Penandatangan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.$route.params.readonly,"id":"Jabatan Penandatangan","state":errors.length > 0 ? false:null,"placeholder":"Jabatan Penandatangan"},model:{value:(_vm.localData.jabatan_ttd),callback:function ($$v) {_vm.$set(_vm.localData, "jabatan_ttd", $$v)},expression:"localData.jabatan_ttd"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Alamat Pos Eletronik","label-for":"Alamat Pos Eletronik"}},[_c('validation-provider',{attrs:{"name":"Alamat Pos Eletronik","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Alamat Pos Eletronik","state":errors.length > 0 ? false:null,"placeholder":"Email","disabled":true},model:{value:(_vm.localData.email),callback:function ($$v) {_vm.$set(_vm.localData, "email", $$v)},expression:"localData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Alamat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Alamat","label-for":"Alamat","state":errors.length > 0 ? false:null}},[_c('b-form-textarea',{attrs:{"id":"Alamat","placeholder":"Alamat...","rows":"3","disabled":true},model:{value:(_vm.localData.alamat),callback:function ($$v) {_vm.$set(_vm.localData, "alamat", $$v)},expression:"localData.alamat"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" Alamat wajib diisi! ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Telepon","label-for":"telepon"}},[_c('validation-provider',{attrs:{"name":"Telepon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Telepon","state":errors.length > 0 ? false:null,"placeholder":"(021) 8225456","disabled":true},model:{value:(_vm.localData.telepon),callback:function ($$v) {_vm.$set(_vm.localData, "telepon", $$v)},expression:"localData.telepon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-3",attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex justify-content-end"},[(!_vm.$route.params.readonly)?_c('b-button',{staticClass:"btn",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.hapusData(_vm.localData.id)}}},[_vm._v("Hapus")]):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }