<template>
      <div>
        <b-button variant="outline-primary" class="mt-2" v-b-modal="`modal_instansi${localData.id}`"  @click="updateParaPihak" v-if="(localData.btn !== 'mitra') && (!$route.params.readonly)">Pilih Instansi/Mitra</b-button>
          <div>
            <b-modal :id="`modal_instansi${localData.id}`" title="Daftar Instansi/Mitra" size="xl" hide-footer>
              <instansi-mitra :pihak="localData.pihak_ke" @emitGetDetailDataKerjasama="reloadDataKerjasama"/>
            </b-modal>
          </div>
      </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Kerjasama from '@/views/laporan/list/List.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BModal,
  BFormFile,
  BMedia,
  BMediaBody,
  BLink,
} from 'bootstrap-vue'
import {
  downloadDokumen,
} from '@/connection-api/laporan'
import { required, email } from '@validations'
import flatPickr from "vue-flatpickr-component";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import 'flatpickr/dist/flatpickr.css';
import InstansiMitra from '@/views/instansi/list/List.vue';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    flatPickr,
    BModal,
    BFormFile,
    BMedia,
    BMediaBody,
    Kerjasama,
    BLink,
    InstansiMitra,
  },
  data() {
    return {
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          dateFormat: "d-m-Y",
          locale: Indonesian,
        },
      },
      required,
    }
  },
  props: {
    localData: {},
  },
  methods: {
    updateParaPihak() {
      this.$emit('emitUpdateParaPihak')
    },
    async lihatData(file) {
      try {
        const resp = await downloadDokumen(file);
        var type_file = file.substring(file.length - 4);
        if (type_file === ".pdf") {
          this.imageFile = URL.createObjectURL(
            new Blob([resp.data], { type: "application/pdf" })
          );
        } else {
          this.imageFile = URL.createObjectURL(
            new Blob([resp.data], { type: "image/jpeg" })
          );
        }
        window.open(this.imageFile);
      } catch (error) {
        this.makeToast("danger", JSON.stringify("File tidak ditemukan!"));
      }
    },
    reloadDataKerjasama()
    {
      this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
    },
  }
}
</script>
