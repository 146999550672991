<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Tambah Data Instansi/Mitra
      </h5>
    </b-card-header>
    <b-card-body>
    <b-row class="mb-2">
       <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <b-media no-body class="mt-3">
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  rounded
                  height="80"
                  :src="localData.logo"
                />
              </b-link>
              <!--/ avatar -->
            </b-media-aside>

            <b-media-body class="mt-75 ml-75 mb-3">
              <!-- upload button -->
              <div class="d-flex">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="$refs.refInputElLogo.$el.click()"
                >
                  Pilih Logo
                </b-button>
                <b-form-file
                  ref="refInputElLogo"
                  v-model="localData.file"
                  accept=".jpg, .png, .jpeg"
                  :hidden="true"
                  plain
                  @input="onFileChangeLogo(localData.file)"
                />
              </div>
              
              <!--/ upload button -->
              <b-card-text>Allowed JPG, JPEG or PNG. Max size of 800kB</b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
    </b-row>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Nama</label>
           <b-form-input
              id="nama"
              autofocus
              trim
              placeholder="Masukkan Nama Instansi"
              v-model="localData.nama"
            />
            <small class="mt-1" style="color: red;" v-if="error.nama">{{error.message}}</small>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Negara</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            :options="negaraOptions"
            @input="(val) => $emit('update:uptFilter', val)"
            v-model="localData.negara_id"
          />
        </b-col>
         <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Provinsi</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('emitLoadKota', val)"
            v-model="localData.provinsi_id"
            :options="provinsiOptions"
          />
        </b-col>
      </b-row>
      <b-row class="mb-2">
         <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Kota/Kabupaten</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            v-model="localData.kota_id"
            :options="kotaOptions"
          />
        </b-col>
         <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Email</label>
            <b-form-input
              id="email"
              trim
              placeholder="Masukkan Email"
              type="email"
              v-model="localData.email"
            />
        </b-col>
         <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Telepon</label>
          <b-form-input
              id="telepon"
              trim
              placeholder="Masukkan Nomor Telepon"
              v-model="localData.telepon"
            />
        </b-col>
      </b-row>
      <b-row class="mb-2">
         <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Alamat</label>
           <b-form-textarea
              id="alamat"
              v-model="localData.alamat"
              placeholder="Masukkan Alamat"
              rows="3"
            ></b-form-textarea>
            <small class="mt-1" style="color: red;" v-if="error.alamat">{{error.message}}</small>
        </b-col>
      </b-row>
      <b-row>
         <b-col
          cols="12"
          md="12"
          class="mb-md-0 mb-2"
        >
          <div class="d-flex justify-content-end">
            <b-button class="btn" variant="primary" @click="tambahData">Tambah</b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput, BFormFile, BMedia, BMediaBody, BMediaAside, BLink, BCardText, BImg, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  tambahDataInstansi,
  uploadFileLogo,
} from '@/connection-api/instansi'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormInput,
    BFormFile,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BCardText,
    BImg,
    BFormTextarea,
  },
  data() {
    return {
      error: {},
      localData: {
        logo: require('@/assets/images/logo/logo-dev.png'),
        file: null,
        negara_id: null,
        provinsi_id: null,
        kota_id: null,
      },
    }
  },
  directives: {
    Ripple,
  },
  props: {
    negaraOptions: {},
    provinsiOptions: {},
    kotaOptions: {},
  },
  methods: {
    async uploadLogo(id)
    {
      if(this.localData.file !== null)
      {
        const responseUpload = await uploadFileLogo(id, this.localData.file, event => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
      }
    },
    async tambahData()
    {
      const response = await tambahDataInstansi(this.localData)
      if(response.data.success === true)
      {
        this.uploadLogo(response.data.id)
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Instansi / Mitra',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.localData.logo = require('@/assets/images/logo/logo-dev.png')
          this.localData.file = null
          this.localData.nama = null
          this.localData.alamat = null
          this.localData.telepon = null
          this.localData.negara_id = null
          this.localData.provinsi_id = null
          this.localData.kota_id = null
          this.localData.email = null
          this.$emit('refetchData')
      }
      else
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Instansi / Mitra',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
          this.error = response.data.error
      }
    },
     onFileChangeLogo(val) {
      if(val !== null)
      {
        this.localData.logo = URL.createObjectURL(val)
      }
    },
    changeUPT(value) {
      this.$emit('emitReceiverUPT', value)
      this.$emit('update:kanwilFilter', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
