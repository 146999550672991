<template>
  <b-card title="Status Pengaduan">
    <app-timeline v-if="logData">

      <app-timeline-item
        :variant="index === 0 ? 'success' : 'secondary'"
        v-for="(data, index) in logData"
        :key="data.id"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6
            v-if="data.status_laporan_modul !== data.status_laporan"
          >
            {{ data.status_laporan_modul }} - {{ data.status_laporan }}
          </h6>
          <h6
            v-else
          >{{ data.status_laporan_modul }}
          </h6>
          <small class="text-muted">{{ formatDateToMonthShort(data.created_at, { hour: 'numeric', minute: 'numeric' }) }}</small>
        </div>
        <small>
          {{ formatDateComplete(data.created_at) }}<br/>
          <div v-if="data.nama">Dilakukan oleh</div>
        </small>
        <b-media v-if="data.nama">
          <template #aside>
            <b-avatar :src="require('@/assets/images/avatars/8-small.png')" />
          </template>
          <h6>{{ data.nama }}</h6>
          <!-- <p class="mb-0">
            CEO of Infibeam
          </p> -->
        </b-media>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BMedia, BAvatar,
} from 'bootstrap-vue'
import { isToday } from '@core/utils/utils'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    logData: {
      required: true,
    },
  },
  mounted() {
    
  },
  methods: {
    formatDateComplete (value, toTimeForCurrentDay = true) {
      const date = new Date(value)
      let formatting = { dateStyle: 'full', timeStyle: 'long', timeZone: 'Asia/Jakarta' }

      return new Intl.DateTimeFormat('id-ID', formatting).format(new Date(value))
    },
    formatDateToMonthShort (value, toTimeForCurrentDay = true) {
      const date = new Date(value)
      let formatting = { year: 'numeric', month: 'short', day: 'numeric' }

      if (toTimeForCurrentDay && isToday(date)) {
        formatting = { hour: 'numeric', minute: 'numeric' }
      }

      return new Intl.DateTimeFormat('id-ID', formatting).format(new Date(value))
    }
  },
  setup(props) {
  },
}
</script>

<style>
.scrollcontent {
  height: 300px;
  overflow: scroll;
}
</style>
