<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Teruskan ke Panel"
      back-button-text="Kembali"
      next-button-text="Lanjut"
      class="mb-3"
       @on-complete="formSubmitted"
    >
      <tab-content
        title="BAP Pelapor/Saksi/Ahli"
        :before-change="bapPelapor"
      >
        <validation-observer
          ref="bapPelaporRules"
          tag="form"
        >
          <bap-pelapor :jenisDokumenOptions="jenisDokumen2Options.options" :localData="localData.bap_pelapor" :pemeriksaanOptions="pemeriksaanOptions.options" />
        </validation-observer>
      </tab-content>
      <tab-content
        title="BAP Konfrontir"
        :before-change="bapKonfrontir"
      >
        <validation-observer
          ref="bapKonfrontirRules"
          tag="form"
        >
          <bap-konfrontir :jenisDokumenOptions="jenisDokumen2Options.options" :localData="localData.bap_konfrontir" :pemeriksaanOptions="pemeriksaanOptions.options" />
        </validation-observer>
      </tab-content>
      <tab-content
        title="Laporan Pemeriksaan Pendahuluan"
        :before-change="pemeriksaanPendahuluan"
      >
        <validation-observer
          ref="pemeriksaanPendahuluanRules"
          tag="form"
        >
          <pemeriksaan-pendahuluan :localData="localData" :jenisDokumenOptions="jenisDokumenOptions.options"/>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Kerjasama from '@/views/laporan/list/List.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BModal,
  BFormFile,
  BMedia,
  BMediaBody,
  BFormDatepicker,
} from 'bootstrap-vue'
import {
  updateTplPemeriksaanPendahuluan,
} from '@/connection-api/master'
import { required, email } from '@validations'
import { codeIcon } from './code'
import flatPickr from "vue-flatpickr-component";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import 'flatpickr/dist/flatpickr.css';
import Korespondensi from "./component/Korespondensi.vue";
import ModalDokumen from './component/ModalDokumen.vue'
import Verifikasi from './component/Verifikasi.vue'
import Penerimaan from './component/Penerimaan.vue'
import BapPelapor from './component/BapPelapor.vue'
import BapKonfrontir from './component/BapKonfrontir.vue'
import PemeriksaanPendahuluan from './component/PemeriksaanPendahuluan.vue'
import {
  getJenisDokumen,
  updateVerifikasi,
} from '@/connection-api/master'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    flatPickr,
    BModal,
    BFormFile,
    BMedia,
    BMediaBody,
    Kerjasama,
    Korespondensi,
    ModalDokumen,
    BFormDatepicker,
    Verifikasi,
    Penerimaan,
    BapPelapor,
    BapKonfrontir,
    PemeriksaanPendahuluan,
  },
  async mounted() {
      const data = {
        dokumen_id: 5,
      }
      const jenisDokumen = await getJenisDokumen(data)
      this.mappingSelectOption(jenisDokumen.data, this.jenisDokumenOptions.options)
      const data2 = {
        jenis: 'Berita Acara',
      }
      const jenisDokumenBeritaAcara = await getJenisDokumen(data2)
      this.mappingSelectOption(jenisDokumenBeritaAcara.data, this.jenisDokumen2Options.options)
  },
  data() {
    return {
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          dateFormat: "d-m-Y",
          locale: Indonesian,
        },
      },
      jenisDokumenOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Dokumen --' },
        ],
      },
      jenisDokumen2Options: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Dokumen --' },
        ],
      },
      centeredModalVisible: false,
      selectedContry: '',
      selectedLanguage: '',
      name: '',
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      first_name: '',
      last_name: '',
      address: '',
      landMark: '',
      pincode: '',
      twitterUrl: '',
      facebookUrl: '',
      googleUrl: '',
      linkedinUrl: '',
      city: '',
      required,
      email,
      codeIcon,
      pemeriksaanOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Pemeriksaan --' },
          { value: 'Konfrontir', label: 'Konfrontir' },
          { value: 'Terlapor', label: 'Terlapor' },
          { value: 'Pelapor/Saksi/Ahli', label: 'Pelapor/Saksi/Ahli' },  
        ],
      },
      countryName: [
        { value: 'select_value', text: 'Select Value' },
        { value: 'Russia', text: 'Russia' },
        { value: 'Canada', text: 'Canada' },
        { value: 'China', text: 'China' },
        { value: 'United States', text: 'United States' },
        { value: 'Brazil', text: 'Brazil' },
        { value: 'Australia', text: 'Australia' },
        { value: 'India', text: 'India' },
      ],
      languageName: [
        { value: 'nothing_selected', text: 'Nothing Selected' },
        { value: 'English', text: 'English' },
        { value: 'Chinese', text: 'Mandarin Chinese' },
        { value: 'Hindi', text: 'Hindi' },
        { value: 'Spanish', text: 'Spanish' },
        { value: 'Arabic', text: 'Arabic' },
        { value: 'Malay', text: 'Malay' },
        { value: 'Russian', text: 'Russian' },
      ],
    }
  },
  props: {
    jenisPenyampaianOptions: {},
    kantorPenghubungOptions: {},
    jenisPerkaraOptions: {},
    lingkunganPeradilanOptions: {},
    provinsiOptions: {},
    kotaOptions: {},
    jenisPihakTerlibatOptions: {},
    localData: {},
  },
  methods: {
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
    async updateBapPelapor()
    {
      this.localData.bap_pelapor.mark = 'bap_pelapor'
      const responseBapPelapor = await updateTplPemeriksaanPendahuluan(this.localData.bap_pelapor)
      this.localData.bap_pelapor = responseBapPelapor.data.data
    },
    async updateBapKonfrontir()
    {
      this.localData.bap_konfrontir.mark = 'bap_konfrontir'
      const responseBapKonfrontir = await updateTplPemeriksaanPendahuluan(this.localData.bap_konfrontir)
      this.localData.bap_konfrontir = responseBapKonfrontir.data.data
    },
    async formSubmitted() {
      if(window.confirm('Apakah anda yakin?'))
      {
        this.localData.jenis_cta = 'pemeriksaan-lanjutan';
        this.localData.laporan_id = this.$route.params.laporan_id
        const response = await updateVerifikasi(this.localData)
        if(response.data.success === true)
            {
              this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Pemeriksaan Pendahuluans',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: response.data.message,
                  },
                })
                this.$emit('refetchData')
                this.$router.push({name: 'tpl-pemeriksaan-lanjutan'})
            }
            else
            {
              this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Pemeriksaan Pendahuluans',
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: response.data.message,
                  },
                })
                this.error = response.data.error
            }
      }
    },
    bapPelapor() {
      return new Promise((resolve, reject) => {
        this.$refs.bapPelaporRules.validate().then(success => {
           if (success) {
              this.updateBapPelapor()
              resolve(true)
            } else {
              reject()
            }
        })
      })
    },
    bapKonfrontir() {
      return new Promise((resolve, reject) => {
        this.$refs.bapKonfrontirRules.validate().then(success => {
            if (success) {
              this.updateBapKonfrontir()
              resolve(true)
            } else {
              reject()
            }
        })
      })
    },
    pemeriksaanPendahuluan() {
      return new Promise((resolve, reject) => {
        this.$refs.pemeriksaanPendahuluanRules.validate().then(success => {
          if(this.$route.params.readonly)
          {
            resolve(true)
          }
          else
          {
             if (success) {
                resolve(true)
              } else {
                reject()
              }
          }
        })
      })
    },
     makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Kerjasama',
        variant,
        solid: true,
        autoHideDelay: 5000,
      })
    },
  },
}
</script>
