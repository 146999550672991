<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Edit Data
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Jenis Dokumen</label>
           <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            v-model="localData.jenis_kelengkapan_id"
            :options="jenisKelengkapanOptions"
          />
            <small class="mt-1" style="color: red;" v-if="error.jenis_kelengkapan_id">{{error.message}}</small>
        </b-col>
         <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Nomor Dokumen</label>
           <b-form-input
              id="no_dokumen"
              autofocus
              trim
              placeholder="Nomor Dokumen"
              v-model="localData.no_dokumen"
            />
            <small class="mt-1" style="color: red;" v-if="error.no_dokumen">{{error.message}}</small>
        </b-col>
         <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Tanggal Dokumen</label>
            <label for="example-datepicker">Pilih Tanggal</label>
            <b-form-datepicker id="example-datepicker" v-model="localData.tgl_dokumen" class="mb-2" :locale="'id'"></b-form-datepicker>
        </b-col>
         <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Nomor Arsip</label>
           <b-form-input
              id="no_arsip"
              autofocus
              trim
              placeholder="Nomor Arsip"
              v-model="localData.no_arsip"
            />
            <small class="mt-1" style="color: red;" v-if="error.no_arsip">{{error.message}}</small>
        </b-col>
         <b-col
          cols="12"
          md="12"
          class="mb-2"
        >
          <label>Judul</label>
           <b-form-input
              id="judul"
              autofocus
              trim
              placeholder="Judul"
              v-model="localData.judul"
            />
            <small class="mt-1" style="color: red;" v-if="error.judul">{{error.message}}</small>
        </b-col>
         <b-col
          cols="12"
          md="12"
          class="mb-2"
        >
          <label>Keterangan</label>
           <quill-editor
              id="deskripsi"
              :options="snowOption"
              style="height: 400px; overflow-y: auto;"
              v-model="localData.keterangan"
            />
            <small class="mt-1" style="color: red;" v-if="error.keterangan">{{error.message}}</small>
        </b-col>
        </b-row>
        <b-row>
         <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
            <b-media no-body>
              <b-media-body class="mt-60 ml-60">
                <label>File</label>
                <div class="d-flex" style="width: 100%;">
                  <b-form-file
                    v-if="!$route.params.readonly"
                    v-model="localData.file"
                    placeholder="Masukkan File"
                  >
                  </b-form-file>
                  <b-button
                    v-if="localData.nama_file != null || localData.nama_file"
                    variant="success"
                    size="sm"
                    class="float-right ml-1"
                    @click="lihatData(localData.nama_file)"
                  >
                    Lihat Dokumen
                  </b-button>
                </div>
                <label class="ml-1" style="color: red;" v-if="!$route.params.readonly">Ukuran file max 5mb</label>
              </b-media-body>
            </b-media>
        </b-col>
         <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2 mt-2"
        >
          <div class="d-flex">
            <b-button class="btn" variant="primary" @click="updateData">Simpan</b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput, BFormFile, BMedia, BMediaBody, BMediaAside, BLink, BCardText, BImg, BFormTextarea, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  getKelengkapanDataById,
  updateKelengkapanData,
  downloadDokumenKelengkapanData,
} from '@/connection-api/master'
import { quillEditor } from 'vue-quill-editor'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormInput,
    BFormFile,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BCardText,
    BImg,
    BFormTextarea,
    BFormDatepicker,
    quillEditor,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      error: {},
      localData: {
        logo: require('@/assets/images/logo/logo-dev.png'),
        file: null,
        negara_id: null,
        provinsi_id: null,
        kota_id: null,
      },
    }
  },
  directives: {
    Ripple,
  },
  props: {
    instansiId: {},
    negaraOptions: {},
    provinsiOptions: {},
    kotaOptions: {},
    showEditData: {},
    jenisKelengkapanOptions: {},
  },
  async mounted() {
    this.loadData()
  },
  methods: {
    async lihatData(file) {
        const resp = await downloadDokumenKelengkapanData(file);
        var type_file = file.substring(file.length - 4);
        if (type_file === ".pdf") {
          this.imageFile = URL.createObjectURL(
            new Blob([resp.data], { type: "application/pdf" })
          );
        } else {
          this.imageFile = URL.createObjectURL(
            new Blob([resp.data], { type: "image/jpeg" })
          );
        }
        window.open(this.imageFile);
    },
    async loadData() {
      const response = await getKelengkapanDataById(this.instansiId)
      if(response.data.success === true)
      {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Kelengkapan Data',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Data berhasil didapatkan!.',
          },
        })
        this.localData = response.data.data
      }
    },
    close() {
      this.$emit('emitCloseEditData')
    },
    async updateData()
    {
      if(this.localData.file !== undefined)
      {
        const responseUpload = await uploadFileKelengkapanData(this.localData.file, event => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
        this.localData.nama_file = responseUpload.data.file
      }

      this.localData.laporan_id = this.$route.params.laporan_id
      const response = await updateKelengkapanData(this.localData)
      if(response.data.success === true)
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kelengkapan Data',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.$emit('refetchData')
          this.$root.$emit('bv::hide::modal', 'modal_edit_kelengkapan_data_' + this.localData.id);
      }
      else
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kelengkapan Data',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
          this.error = response.data.error
      }
    },
     onFileChangeLogo(val) {
      if(val !== null)
      {
        this.localData.logo = URL.createObjectURL(val)
      }
    },
    changeUPT(value) {
      this.$emit('emitReceiverUPT', value)
      this.$emit('update:kanwilFilter', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
