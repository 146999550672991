<template>
      <div>
        <b-button variant="outline-primary" class="mt-2" v-b-modal.modal_dokumen @click="updateDataKerjasama">Unggah Dokumen</b-button>
          <div>
            <b-modal id="modal_dokumen" title="Unggah Dokumen" hide-footer>
              <b-row>
                <b-col md="12" class="mb-2">
                  <label class="ml-1">Unggah surat permohonan usulan<span style="color: red;"> *</span></label>
                  <b-media no-body>
                    <b-media-body class="mt-75 ml-75">
                      <div class="d-flex" style="width: 100%;">
                        <b-form-file
                          v-if="!$route.params.readonly"
                          v-model="localDataDok.surat_permohonan_usulan"
                          placeholder="Masukkan File"
                        >
                        </b-form-file>
                        <b-button
                          variant="primary"
                          size="sm"
                          class="float-right ml-1"
                          @click="updateData(localDataDok.surat_permohonan_usulan, 8, 'surat_permohonan_usulan')"
                          v-if="!$route.params.readonly"
                        >
                          Unggah
                        </b-button>
                        <b-button
                          v-if="localDataDok.show_surat_permohonan_usulan != null || localData.show_surat_permohonan_usulan"
                          variant="success"
                          size="sm"
                          class="float-right ml-1"
                          @click="lihatData('surat_permohonan_usulan')"
                        >
                          Lihat Dokumen
                        </b-button>
                      </div>
                      <label class="ml-1" style="color: red;" v-if="!$route.params.readonly">Ukuran file max 5mb</label>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col md="12" class="mb-2">
                  <label class="ml-1">Unggah draft usulan</label>
                  <b-media no-body>
                    <b-media-body class="mt-75 ml-75">
                      <div class="d-flex" style="width: 100%;">
                        <b-form-file
                          v-if="!$route.params.readonly"
                          v-model="localDataDok.draft_usulan"
                          placeholder="Masukkan File"
                        >
                        </b-form-file>
                        <b-button
                          variant="primary"
                          size="sm"
                          class="float-right ml-1"
                          @click="updateData(localDataDok.draft_usulan, 9, 'draft_usulan')"
                          v-if="!$route.params.readonly"
                        >
                          Unggah
                        </b-button>
                        <b-button
                          v-if="localDataDok.show_draft_usulan != null || localData.show_draft_usulan"
                          variant="success"
                          size="sm"
                          class="float-right ml-1"
                          @click="lihatData('draft_usulan')"
                        >
                          Lihat Dokumen
                        </b-button>
                      </div>
                      <label class="ml-1" style="color: red;" v-if="!$route.params.readonly">Ukuran file max 5mb</label>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col md="12" class="mb-2">
                  <label class="ml-1">Unggah rencana kerja</label>
                  <b-media no-body>
                    <b-media-body class="mt-75 ml-75">
                      <div class="d-flex" style="width: 100%;">
                        <b-form-file
                          v-if="!$route.params.readonly"
                          v-model="localDataDok.rencana_kerja"
                          placeholder="Masukkan File"
                        >
                        </b-form-file>
                        <b-button
                          variant="primary"
                          size="sm"
                          class="float-right ml-1"
                          @click="updateData(localDataDok.rencana_kerja, 10, 'rencana_kerja')"
                          v-if="!$route.params.readonly"
                        >
                          Unggah
                        </b-button>
                        <b-button
                          v-if="localDataDok.show_rencana_kerja != null || localData.show_rencana_kerja"
                          variant="success"
                          size="sm"
                          class="float-right ml-1"
                          @click="lihatData('rencana_kerja')"
                        >
                          Lihat Dokumen
                        </b-button>
                      </div>
                      <label class="ml-1" style="color: red;" v-if="!$route.params.readonly">Ukuran file max 5mb</label>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-modal>
          </div>
      </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Kerjasama from '@/views/laporan/list/List.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BModal,
  BFormFile,
  BMedia,
  BMediaBody,
  BLink,
} from 'bootstrap-vue'
import {
  downloadDokumen,
  uploadDokumen,
} from '@/connection-api/laporan'
import { required, email } from '@validations'
import flatPickr from "vue-flatpickr-component";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import 'flatpickr/dist/flatpickr.css';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    flatPickr,
    BModal,
    BFormFile,
    BMedia,
    BMediaBody,
    Kerjasama,
    BLink,
  },
  data() {
    return {
      localDataDok: {
        show_draft_usulan: null,
        show_rencana_kerja: null,
        show_surat_permohonan_usulan: null,
      },
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          dateFormat: "d-m-Y",
          locale: Indonesian,
        },
      },
      required,
    }
  },
  props: {
    localData: {},
  },
  methods: {
    updateDataKerjasama() {
      this.$emit('emitUpdateDataKerjasama')
    },
    async lihatData(file) {
      try {
        switch (file) {
          case 'draft_usulan':
            file = this.localData.show_draft_usulan ? this.localData.show_draft_usulan : this.localDataDok.show_draft_usulan
            break;
        
          case 'rencana_kerja':
            file = this.localData.show_rencana_kerja ? this.localData.show_rencana_kerja : this.localDataDok.show_rencana_kerja
            break;

          case 'surat_permohonan_usulan':
            file = this.localData.show_surat_permohonan_usulan ? this.localData.show_surat_permohonan_usulan : this.localDataDok.show_surat_permohonan_usulan
            break;
        }
        const resp = await downloadDokumen(file);
        var type_file = file.substring(file.length - 4);
        if (type_file === ".pdf") {
          this.imageFile = URL.createObjectURL(
            new Blob([resp.data], { type: "application/pdf" })
          );
        } else {
          this.imageFile = URL.createObjectURL(
            new Blob([resp.data], { type: "image/jpeg" })
          );
        }
        window.open(this.imageFile);
      } catch (error) {
        this.makeToast("danger", JSON.stringify("File tidak ditemukan!"));
      }
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Kerjasama',
        variant,
        solid: true,
        autoHideDelay: 5000,
      })
    },
    async updateData(file, jenisDokumen, sub_var) {
      const responseUpload = await uploadDokumen(this.localData.id, file, jenisDokumen, event => {
        this.progress = Math.round((100 * event.loaded) / event.total)
      })
      if(responseUpload.data.success === true)
      {
        this.makeToast('success', 'File berhasil di upload')
        if(sub_var)
        {
          switch (sub_var) {
            case 'surat_permohonan_usulan':
              this.localDataDok.show_surat_permohonan_usulan = responseUpload.data.data.nama_file
              break;
          
            case 'draft_usulan':
              this.localDataDok.show_draft_usulan = responseUpload.data.data.nama_file
              break;

            case 'rencana_kerja':
              this.localDataDok.show_rencana_kerja = responseUpload.data.data.nama_file
              break;
          }
        }
       console.log(this.localDataDok)
      }
      else
      {
        this.makeToast('danger', responseUpload.data.message)
      }
    },
  }
}
</script>
