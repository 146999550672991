<template>
  <b-card
    class="blog-edit-wrapper"
  >
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <div class="mr-1">
              <label for="mulai">Tanggal Mulai</label>
              <b-form-datepicker id="mulai" v-model="localData.tanggal_mulai" class="mb-2" :locale="'id'"></b-form-datepicker>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mb-md-0 mb-2"
        >
          <div class="mr-1">
              <label for="selesai">Tanggal Selesai</label>
              <b-form-datepicker id="selesai" v-model="localData.tanggal_selesai" class="mb-2" :locale="'id'"></b-form-datepicker>
          </div>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Deskripsi"
            label-for="Deskripsi"
            class="mb-2"
          >
            <quill-editor
              id="deskripsi"
              :options="snowOption"
              style="height: 400px; overflow-y: auto;"
              v-model="localData.deskripsi"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
            <div class="mt-2">
              <b-button class="btn btn-primary" variant="primary" @click="tambahData()">Simpan</b-button>
            </div>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    tambahKasusPosisi,
} from '@/connection-api/master'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
      error: {},
      localData: {
        logo: require('@/assets/images/logo/logo-dev.png'),
        file: null,
        negara_id: null,
        provinsi_id: null,
        kota_id: null,
      },
    }
  },
  methods: {
    async tambahData()
    {
      this.localData.laporan_id = this.$route.params.laporan_id
      const response = await tambahKasusPosisi(this.localData)
      if(response.data.success === true)
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Tambah Data',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.$emit('refetchData')
          this.$root.$emit('bv::hide::modal', 'modal_tambah_kasus_posisi');
      }
      else
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Tambah Data',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
          this.error = response.data.error
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
