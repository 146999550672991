<template>

  <div>

     <!-- Tambah Data -->
    <div>
      <b-modal id="modal_tambah_kelengkapan_data" size="lg" title="Kelengkapan Data" ref="btnClose" hide-footer v-if="$route.params.laporan_id">
        <tambah-data
          :jenisKelengkapanOptions="jenisKelengkapanOptions.options"
          @emitLoadKota="onchangeProvinsi"
          @refetchData="refetchData"
          @emitPilihInstansi="pilihInstansi"
          @emitCloseTambahData="closeTambahData"
          @closeTambahData="closeTambahData"
        />
      </b-modal>
    </div>

    <!-- Filters -->
    <!-- <list-filters
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button class="btn" v-b-modal.modal_tambah_kelengkapan_data variant="outline-primary" v-if="$can('manage','all') && !$route.params.mode && !buttonOff">Tambah Kelengkapan Data</b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchDatas"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(keterangan)="data">
          <b-media vertical-align="center">
              <div v-html="data.item.keterangan"></div>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
            <div class="d-flex" v-if="!$route.params.mode || !buttonOff">
               <b-button
                class="btn-icon btn btn-relief-primary mr-1"
                variant="outline-info"
                @click="lihatData(data.item.nama_file)" title="Lihat Dokumen" alt="Lihat Dokumen"
                v-if="data.item.nama_file"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>

              <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="info" v-b-modal="'modal_edit_kelengkapan_data_'+data.item.id" title="Ubah Data" v-if="!$route.params.mode || !buttonOff">
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-modal :id="'modal_edit_kelengkapan_data_'+data.item.id" size="lg" title="Kelengkapan Data" ref="btnClose" hide-footer v-if="$route.params.laporan_id || !buttonOff">
                <edit-data
                  :instansiId="data.item.id"
                  @emitCloseEditData="closeEditData"
                  :negaraOptions="negaraOptions.options"
                  :provinsiOptions="provinsiOptions.options"
                  :kotaOptions="kotaOptions.options"
                  :jenisKelengkapanOptions="jenisKelengkapanOptions.options"
                  @emitLoadKota="onchangeProvinsi"
                  @refetchData="refetchData"
                  @emitReloadDataKerjasama="reloadDataKerjasama"
                />
              </b-modal>

              <b-button
                class="btn-icon btn btn-relief-primary"
                variant="outline-danger"
                v-b-modal="`modal-hapus-kelengkapan-data-${data.item.id}`"
                title="Hapus Data" alt="Hapus Data"
                v-if="!$route.params.mode || !buttonOff"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
            
            <div>
              <b-modal :id="`modal-hapus-kelengkapan-data-${data.item.id}`" centered title="Konfirmasi">
                <div class="d-flex justify-content-center">
                  <h4 class="my-4">Apakah anda yakin ingin kelengkapan data {{data.item.judul}}?</h4>
                </div>
                <template #modal-footer>
                  <b-button variant="danger" @click="deleteData(data.item.id, data.item.nama_file)">Hapus</b-button>
                  <b-button variant="secondary" @click="$bvModal.hide(`modal-hapus-kelengkapan-data-${data.item.id}`)">Batal</b-button>
                </template>
              </b-modal>
            </div>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Menampilkan {{ dataMeta.from }} per {{ dataMeta.to }} dari {{ dataMeta.of }} data </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/connection-api/kelengkapan-data/storeModule'
import useList from '@/connection-api/kelengkapan-data/list'
import TambahData from './TambahData.vue'
import EditData from './EditData.vue'
import listFilters from './ListFilters.vue'
import {
  getNegara,
  getProvinsi,
  getKota,
  deleteKelengkapanData,
  getJenisKelengkapan,
  downloadDokumenKelengkapanData,
} from '@/connection-api/master'

export default {
  components: {
    listFilters,
    TambahData,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    EditData,
    vSelect,
  },
  data() {
    return {
      instansiId: null,
      showTambahData: false,
      showEditData: false,
      negaraOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Negara --' },
        ],
      },
      provinsiOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Provinsi --' },
        ],
      },
      kotaOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kota --' },
        ],
      },
      jenisKelengkapanOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Dokumen --' },
        ],
      },
    }
  },
  props: {
    pihak: {},
    jadwal_pembahasan_id: {},
    buttonOff: {},
  },
  async mounted() {
      if(this.$route.params.laporan_id)
      {
         this.laporanId = this.$route.params.laporan_id
      }
      const respNegara = await getNegara()
      this.mappingSelectOption(respNegara.data, this.negaraOptions.options)

      const respProvinsi = await getProvinsi()
      this.mappingSelectOption(respProvinsi.data, this.provinsiOptions.options)

      const jenisKelengkapan = await getJenisKelengkapan()
      this.mappingSelectOption(jenisKelengkapan.data, this.jenisKelengkapanOptions.options)
    },
  methods: {
    async lihatData(file) {
        try {
            const resp = await downloadDokumenKelengkapanData(file);
            console.log(resp)
            var type_file = file.substring(file.length - 4);
            if (type_file === ".pdf") {
                this.imageFile = URL.createObjectURL(
                    new Blob([resp.data], { type: "application/pdf" })
                );
            } else {
                this.imageFile = URL.createObjectURL(
                    new Blob([resp.data], { type: "image/jpeg" })
                );
            }
            const newWindow = window.open(this.imageFile);
            if (!newWindow || newWindow.closed) {
                throw new Error("Failed to open new window or it was immediately closed.");
            }
        } catch (error) {
            console.error("Error while fetching or displaying data:", error);
            // Handle error accordingly, e.g., show a message to the user
        } finally {
            // Cleanup: revoke the object URL to release resources
            if (this.imageFile) {
                URL.revokeObjectURL(this.imageFile);
            }
        }
    },
    closeEditData(){
      this.showEditData = false
    },
    closeTambahData(){
      this.showTambahData = false
    },
    editData(id) {
      this.showTambahData = false
      this.showEditData = false
      this.instansiId = id
      this.showEditData = true
    },
    async pilihInstansi(id)
    {
      const data = {
        unit_utama_id: id,
        kerjasama_id: this.$route.params.id,
        pihak_ke: this.pihak,

      }
      const response = await pilihUnitUtama(data)
      if(response.data.success === true)
      {
         this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Kelengkapan Data',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
            this.refetchData()
            this.reloadDataKerjasama()
      }
    },
    async undang(id, jadwalPembahasanId)
    {
      const data = {
        jadwal_pembahasan_id: jadwalPembahasanId,
        unit_utama_id: id,
      }

      const response = await undangUnitUtama(data)
      if(response.data.success === true)
      {
         this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Kelengkapan Data',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
            this.refetchData()
      }
    },
    reloadDataKerjasama() {
      this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
    },
    async deleteData(id, nama) 
    {
    
        const data = {
          id: id
        }
        const response = await deleteKelengkapanData(data)
        if(response.data.success === true)
        {
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Kelengkapan Data',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Data berhasil dihapus',
              },
            })
            this.refetchData()
            this.$emit('reloadData')
            this.$root.$emit('bv::hide::modal', 'modal-hapus-kelengkapan-data-' + id);
        }
        else
        {
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Kelengkapan Data',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.message,
              },
            })
        }
      
    },
    async onchangeProvinsi(provinsiId)
    {
      const data = {
        provinsi_id: provinsiId
      }
      this.kotaOptions = {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kota --' },
        ],
      }
      const respKota = await getKota(data)
      this.mappingSelectOption(respKota.data, this.kotaOptions.options)
    },
    TambahData()
    {
      if(this.showTambahData === false)
      {
        this.showTambahData = true;
        this.showEditData = false
      }
      else
      {
        this.showTambahData = false;
        this.showEditData = false
      }
    },
    emitAddSuccess(assetId) {
      this.$router.replace(`/kelengkapan-data/edit/${assetId}`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kelengkapan Data',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Data berhasil di tambahkan',
            },
          })
        })
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'kelengkapan-data'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      dataKerjasama,
      peserta,
      laporanId,
    } = useList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      dataKerjasama,
      peserta,
      laporanId,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
